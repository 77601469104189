import React from "react";
import { graphql, Link } from "gatsby";

import ReactMarkdown from "react-markdown";
import Moment from "react-moment";

import Layout from "../components/Layout";
import { Grid, Typography } from "@material-ui/core";

import "./scss/article.scss";

export const query = graphql`
  query ArticleQuery($id: String) {
    strapiArticle(strapiId: { eq: $id }) {
      strapiId
      body
      date
      mainImage {
        publicURL
      }
      title
      restaurants {
        name
        slug
      }
    }
  }
`;

const Article = ({ data }) => {
  const article = data.strapiArticle;

  return (
    <Layout>
      <div className="article">
        <Grid container>
          <Grid item xs={12}>
            {article.restaurants &&
              article.restaurants.map((r, rIndex) => {
                return (
                  <Link
                    key={rIndex}
                    to={`/restaurants/${r.slug}`}
                    className="no-style"
                  >
                    <Typography
                      component="h2"
                      variant="subtitle1"
                      align="center"
                      display="inline"
                      className="restaurant-name"
                    >
                      {r.name}
                    </Typography>
                  </Link>
                );
              })}

            <Typography component="h1" variant="h4" className="article-title">
              {article.title}
            </Typography>
            <Typography component="h3" variant="subtitle2" className="date">
              <Moment format="MMMM Do, YYYY">{article.date}</Moment>
            </Typography>
            <img
              src={
                article.mainImage
                  ? article.mainImage.publicURL
                  : "https://baconmockup.com/600/400?test"
              }
              alt="[NEED TO CHANGE]"
              className="main-image"
            />
          </Grid>
          <Grid item xs={12}>
            <div className="article-info">
              <ReactMarkdown source={article.body} />
            </div>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default Article;
